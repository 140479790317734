import React, { useEffect, useState } from "react";
import { If } from "../../../../common/components/If";
import { Box, CircularProgress } from "@mui/material";
import { CustomRadioGroup } from "../../../../common/components/CustomRadioGroup";
import { getDataByPricingId } from "../../pricingList.thunk";
import { useSelector } from "react-redux";
import { selectTsoSelectableValues } from "../../pricingList.selector";
import { useAppDispatch } from "../../../../common/hooks/default";
import { Field } from "../../pricingListSlice";

interface RadioButtonFieldProps {
	field: Field;
	selectedValue: string;
	loader: boolean;
	pricingId: number;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	errors?: string;
}

function RadioButtonField({
	field,
	loader,
	selectedValue,
	onChange,
	errors,
	pricingId,
}: RadioButtonFieldProps) {
	const [options, setOptions] = useState(field.selectable_values ?? []);
	const tsoSelectableValues = useSelector(selectTsoSelectableValues);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (field?.name === "tso" && field?.data_url && pricingId) {
			dispatch(
				getDataByPricingId(field.data_url, pricingId, "pricingTso")
			);
		}
	}, [field, pricingId]);

	useEffect(() => {
		if (tsoSelectableValues?.length && !field.selectable_values) {
			setOptions(tsoSelectableValues);
		}
	}, [tsoSelectableValues]);

	return (
		<>
			<If condition={loader}>
				<CircularProgress color="primary" />
			</If>
			<If condition={!loader}>
				<Box
					sx={{
						marginLeft: "30px",
						label: {
							fontSize: "18px",
							marginTop: "-2px",
						},
					}}
				>
					<CustomRadioGroup
						value={selectedValue}
						title={field.title ?? ""}
						onChange={onChange}
						options={options}
						errorMessage={errors}
					/>
				</Box>
			</If>
		</>
	);
}

export default RadioButtonField;
