import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";
import { useGetQuickSightDashBoardUrlQuery } from "./geodeApi";
import { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export const style = {
	container: {
		height: "100%",
		overflow: "hidden",
	},
	progressWrapper: {
		display: "flex",
		justifyContent: "center",
	},
};

const ReportingPage = () => {
	const containerRef = useRef<string | HTMLElement>("");
	const { data, isLoading } = useGetQuickSightDashBoardUrlQuery({
		url: "/quicksight/dashboard/contract-data-quality",
	});

	useEffect(() => {
		if (data) {
			const embedDashboard = async () => {
				const embeddingContext = await createEmbeddingContext();

				const frameOptions = {
					url: data.embed_url,
					container: containerRef.current,
					height: "100%",
					width: "100%",
					withIframePlaceholder: true,
				};

				const contentOptions = {
					toolbarOptions: {
						export: false,
						undoRedo: false,
						reset: false,
					},
				};

				await embeddingContext.embedDashboard(
					frameOptions,
					contentOptions
				);
			};

			embedDashboard();
		}
	}, [data?.embed_url, containerRef.current]);

	return (
		<>
			{isLoading && (
				<Box sx={style.progressWrapper}>
					<CircularProgress data-testid="loader" />
				</Box>
			)}
			<Box sx={style.container} ref={containerRef}></Box>
		</>
	);
};

export default ReportingPage;
