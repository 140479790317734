/* eslint-disable */
import {
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";
import { ErrorText } from "./ErrorText";

export interface RadioOption {
	value: string;
	label: string;
}

export interface MuiRadioGroupProps {
	value: string;
	title: string;
	onChange: (event: any) => void;
	options: RadioOption[];
	description?: string;
	errorMessage?: string;
	isDisabled?: boolean;
}

export const CustomRadioGroup = ({
	value,
	onChange,
	options,
	title,
	description,
	errorMessage = "",
	isDisabled = false,
}: MuiRadioGroupProps) => {
	return (
		<FormControl sx={{ marginTop: "16px" }}>
			<FormLabel id="demo-controlled-radio-buttons-group">
				{title}
			</FormLabel>
			<RadioGroup
				name={`radio-buttons-group-${value}`}
				value={value}
				onChange={onChange}
			>
				{options.map((option, index) => (
					<FormControlLabel
						key={option.value}
						label={option.label}
						value={option.value}
						disabled={isDisabled}
						control={<Radio />}
					/>
				))}
			</RadioGroup>
			<Typography variant="subtitle2">{description}</Typography>
			<ErrorText>{errorMessage}</ErrorText>
		</FormControl>
	);
};
