import { apiGet, apiPost } from "../../common/utils/request.utils";
import { AppDispatch } from "../../store";
import {
	getCommodityFixingsSuccess,
	getCommodityFixingsError,
	CommodityFixings,
	createPricingRequestError,
	createPricingRequestSuccess,
	getPricingTemplatesSuccess,
	getPricingTemplateError,
	addNewPricingTemplates,
	addNewPricingTemplatesErrors,
	enablePricingTemplatesLoader,
} from "./PricingRequestStepper.slice";
import { enqueueSnackbar } from "notistack";
import { PricingRequestAPI } from "../pricing_request/pricingRequestStepper.schema";
import { resetDateHours } from "./utils/helpers.utils";
import { PricingStepperType } from "./hooks/usePricingStepper";

export function getCommodityFixings(type: PricingStepperType): any {
	return function (dispatch: AppDispatch) {
		return apiPost(
			`external-services/commodity_fixings?family=${type}`,
			{}
		).then(
			(result) => {
				return dispatch(
					getCommodityFixingsSuccess(
						result.data?.data.map((el: CommodityFixings) => {
							el.label = el.name;
							return el;
						})
					)
				);
			},
			(error) => {
				return dispatch(getCommodityFixingsError(error.message));
			}
		);
	};
}

export function createPricingRequest(pricingRequest: PricingRequestAPI): any {
	return async function (dispatch: AppDispatch) {
		try {
			await apiPost("pricing", pricingRequest);
			enqueueSnackbar(`Pricings are under creation`, {
				variant: "success",
				autoHideDuration: 3000,
			});
			dispatch(createPricingRequestSuccess());
			return true;
		} catch (error: any) {
			if (error.response.data.errors) {
				error.response.data.errors.map(
					(e: { type: string; msg: string }) =>
						enqueueSnackbar(e.msg, {
							variant: "error",
							autoHideDuration: 6000,
						})
				);
			}
			dispatch(createPricingRequestError(error));
			return false;
		}
	};
}

export function getAllPricingTemplates(): any {
	return function (dispatch: AppDispatch) {
		return apiGet(`pricing-templates`).then(
			(result) => {
				return dispatch(getPricingTemplatesSuccess(result.data?.data));
			},
			(error) => {
				return dispatch(getPricingTemplateError(error.message));
			}
		);
	};
}
export function setNewPricingTemplate(title: string, params: any[string]): any {
	return function (dispatch: AppDispatch) {
		dispatch(enablePricingTemplatesLoader);
		const dateKeys: any[] = [
			"startDate",
			"pricingDate",
			"gooClickDate",
			"endDate",
			"gooStartDate",
			"gooEndDate",
			"clickLimitDate",
		];

		dateKeys.map(
			(key: string) => (params[key] = resetDateHours(params[key]))
		);
		return apiPost(`pricing-templates`, { title, params }).then(
			(result) => {
				return dispatch(addNewPricingTemplates(result.data));
			},
			(error) => {
				return dispatch(
					addNewPricingTemplatesErrors(
						error?.response?.data?.msg || error.message
					)
				);
			}
		);
	};
}
