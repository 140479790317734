import React, { useMemo } from "react";
import { convertToMonthYearFormat, OverviewCardprops, style } from "./common";
import { Box, Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import { PrimaryButton } from "../../../../common/components/CustomButton";
import { PricingStepperStep } from "../../hooks/usePricingStepper";
import { ModeEdit } from "@mui/icons-material";

export interface GooOverviewCardProps extends OverviewCardprops {}

export function GooOverviewCard(props: GooOverviewCardProps) {
	const { pricingValues, setStep } = props;
	const isGooFixPricing = () => {
		return pricingValues.gooPricingType.filter(
			(pt: { id: number; name: string }) => pt.name == "Fix"
		).length;
	};
	const gooGranularities = useMemo(() => {
		const all = [];
		if (pricingValues.granularityMonthly) {
			all.push("Monthly");
		}
		if (pricingValues.granularityCal) {
			all.push("CAL");
		}
		if (pricingValues.granularityQuarter) {
			all.push("Quarter");
		}
		if (pricingValues.granularityWholeHorizon) {
			all.push("Whole Horizon");
		}
		return all.join(", ");
	}, [pricingValues]);
	return (
		<Grid item xs={4} sx={style.overviewGridItem}>
			<Box sx={style.overviewBox}>
				<Typography
					variant="h3"
					component="h2"
					sx={style.overviewBoxTitle}
				>
					GOO pricing
				</Typography>
				<Typography component="p">
					Fix Price: {isGooFixPricing() ? "Yes" : "No"}
				</Typography>
				<Typography component="p">
					Formula: {pricingValues.formula}
				</Typography>
				<Typography component="p">
					Period:{" "}
					{convertToMonthYearFormat(pricingValues.gooStartDate)} -{" "}
					{convertToMonthYearFormat(pricingValues.gooEndDate)}
				</Typography>
				<Typography component="p">
					<Typography component="span">{gooGranularities}</Typography>
				</Typography>
				<Typography component="p">
					Clickability Options:{" "}
					{pricingValues.clickOptionSwitch ? "Yes" : "No"}
				</Typography>
				{pricingValues.clickOptionSwitch && (
					<Typography component="p">
						Granularity: {pricingValues.clickGranularity}
					</Typography>
				)}
				{pricingValues.clickOptionSwitch && (
					<Typography component="p">
						Number of clicks: {pricingValues.clickCount}
					</Typography>
				)}
				<Typography component="p">
					Click limit date:{" "}
					{format(pricingValues.clickLimitDate, "dd/MM/yyyy")}
				</Typography>
				<PrimaryButton
					text="Edit"
					type="button"
					color="secondary"
					sx={{
						...style.overViewFloatingButton,
						width: 160,
					}}
					onClick={() => setStep(PricingStepperStep.GOO_ONLY)}
				>
					<ModeEdit sx={{ color: "#334551" }} />
				</PrimaryButton>
			</Box>
		</Grid>
	);
}
