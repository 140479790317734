import { useMemo } from "react";
import { ReactCountryFlag } from "react-country-flag";
import { ColModel } from "../components/GenericTableRow";
import { formatCoordinates, makeLatLongColumn } from "./helpers";
import {
	Portfolio,
	Site,
	isPortfolio,
} from "../../features/sites/sites.module";

export interface TenderFormSiteTableModelParams {
	onDeletePortfolio: (m: Portfolio) => void;
	onDeleteSite: (m: Site) => void;
	selectPortfolio: (m: Portfolio) => void;
	renamePortfolio: (rename: boolean) => void;
	openPortfolioSiteModal: (id: number) => void;
	refreshSiteCluster: (m: Site) => Promise<void>;
}

export const useGemforceSiteTableModel = () => {
	const rowModel: ColModel<Site | Portfolio>[] = useMemo(() => {
		const rows = [
			{
				id: "site_id",
				header: () => "Id",
				xs: 1,
				hidden: true,
				accessor: (m: Site | Portfolio) => m.id,
			},
			{
				id: "asset_id",
				header: () => "TPR Id",
				xs: 1,
				hidden: false,
				accessor: (m: Site | Portfolio) =>
					isPortfolio(m) ? "-" : m.asset_id,
				sortable: true,
			},
			{
				id: "specific_name",
				header: () => "Name",
				xs: 2,
				accessor: (m: Site | Portfolio) => (
					<>
						{m.hasOwnProperty("specific_name") &&
						(m as Site).specific_name
							? (m as Site).specific_name
							: m.name}
						{"children" in m && m.children
							? `(${m.children.length})`
							: ""}
					</>
				),
				sortable: true,
			},
			{
				id: "country",
				header: () => "Country",
				xs: 1,
				accessor: (m: Site | Portfolio) => (
					<>
						{"country" in m && (
							<>
								<ReactCountryFlag
									countryCode={m.country ?? ""}
									svg
									title={m.country ?? ""}
								/>

								{m.country}
							</>
						)}
					</>
				),
			},

			{
				id: "tso",
				header: () => "Tso",
				xs: 1,
				accessor: (m: Site | Portfolio) =>
					isPortfolio(m) ? "-" : m?.tso,
			},
			{
				id: "technology",
				header: () => "Technology",
				xs: 1,
				accessor: (m: Site | Portfolio) =>
					"techno" in m && m?.techno?.name,
			},
			{
				id: "installed_capacity_mw",
				header: () => "Installed capacity",
				xs: 1,
				accessor: (m: Site | Portfolio) => (
					<>
						{"installed_capacity_mw" in m &&
							(m.installed_capacity_mw ?? "")}
						{!("installed_capacity_mw" in m)
							? (m as Portfolio).children.reduce(
									(t: number, s: Site) =>
										t + (s.installed_capacity_mw ?? 0),
									0
							  )
							: ""}
					</>
				),
				sortable: true,
			},
			{
				id: "max_injection",
				header: () => "Max Grid Injection",
				xs: 1.2,
				accessor: (m: Site | Portfolio) =>
					isPortfolio(m) ? "-" : m.max_grid_injection_mw || "-",
			},
			{
				id: "park_age",
				header: () => "Park age",
				xs: 1,
				accessor: (m: Site | Portfolio) => m?.park_age,
				sortable: true,
			},

			...makeLatLongColumn(
				(m: Site | Portfolio) =>
					isPortfolio(m) ? "" : formatCoordinates(4, m).latitude,
				(m: Site | Portfolio) =>
					isPortfolio(m) ? "" : formatCoordinates(4, m).longitude
			),
		];
		return rows;
	}, []);

	return rowModel;
};
