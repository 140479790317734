import { Box, Tooltip, SxProps, Theme } from "@mui/material";

export type SxWithMandatoryWidth = SxProps<Theme> & { width: number | string };

export interface GenericTableRowTooltipProps {
	value?: string;
	sx?: SxWithMandatoryWidth;
}

export function GenericTableRowTooltip(props: GenericTableRowTooltipProps) {
	const { value, sx } = props;
	return (
		<Tooltip title={value}>
			<Box component={"span"} sx={sx ?? {}}>
				{value}
			</Box>
		</Tooltip>
	);
}
