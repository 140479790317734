import React, { useMemo } from "react";
import { convertToMonthYearFormat, OverviewCardprops, style } from "./common";
import { Box, Grid, Typography } from "@mui/material";
import { PricingRequestDataKeys } from "../PricingStep";
import { PrimaryButton } from "../../../../common/components/CustomButton";
import { If } from "../../../../common/components/If";
import { PricingStepperStep } from "../../hooks/usePricingStepper";
import { ModeEdit } from "@mui/icons-material";

export interface PowerOverviewCardProps extends OverviewCardprops {}

export function PowerOverviewCard(props: PowerOverviewCardProps) {
	const isFixPricing = () => {
		return props.pricingValues.pricingType.filter(
			(pt: { id: number; name: string }) => pt.name == "Fix"
		).length;
	};

	const isClickPricing = () => {
		return props.pricingValues.pricingType.filter(
			(pt: { id: number; name: string }) => pt.name == "Click"
		).length;
	};
	const getListOfPricingTypes = () => {
		return props.pricingValues.pricingType.filter(
			(pt: { id: number; name: string }) =>
				pt.name != "Fix" && pt.name != "Click"
		);
	};

	const granularities = useMemo(() => {
		const all = [];
		if (props.pricingValues.granularityMonthly) {
			all.push("Monthly");
		}
		if (props.pricingValues.granularityCal) {
			all.push("CAL");
		}
		if (props.pricingValues.granularityQuarter) {
			all.push("Quarter");
		}
		if (props.pricingValues.granularityWholeHorizon) {
			all.push("Whole Horizon");
		}
		return all.join(", ");
	}, [props.pricingValues]);

	return (
		<Grid item xs={6} sx={style.overviewGridItem}>
			<Box sx={style.overviewBox}>
				<Typography
					variant="h3"
					component="h2"
					sx={style.overviewBoxTitle}
				>
					Power pricing
				</Typography>
				<Typography component="p">
					Fix: {isFixPricing() ? "Yes" : "No"}
				</Typography>
				<Typography component="p">
					Click: {isClickPricing() ? "Yes" : "No"}
				</Typography>
				<Typography component="p">Index:</Typography>
				{getListOfPricingTypes().map((pt, key) => (
					<Typography component="li" key={key}>
						{pt.geco_name || pt.name}
					</Typography>
				))}
				<Typography component="p">GOO:</Typography>
				<Typography component="li">
					Enabled:{" "}
					{props.pricingValues.ppaWithGooSwitch ? "Yes" : "No"}{" "}
				</Typography>
				{props.pricingValues.ppaWithGooSwitch && (
					<Typography component="li">
						Power Adjustement:{" "}
						{props.pricingValues.gooPowerAdjustment}{" "}
					</Typography>
				)}
				<Typography component="p">
					Period:{" "}
					{convertToMonthYearFormat(props.pricingValues.startDate)} -{" "}
					{convertToMonthYearFormat(props.pricingValues.endDate)}
				</Typography>
				<Typography component="p">
					<Typography component="span">{granularities}</Typography>
				</Typography>
				{props.pricingValues.baseOptionSwitch &&
					props.pricingValues.baseCapacity && (
						<Typography component="p">
							Base Capacity Volume:{" "}
							{props.pricingValues.baseCapacity}
						</Typography>
					)}

				<Typography component="p">
					Clickability Options:{" "}
					{props.pricingValues.clickOptionSwitch ? "Yes" : "No"}
				</Typography>
				{props.pricingValues.clickOptionSwitch && (
					<Typography component="p">
						Granularity:
						<Typography component="ul">
							{props.pricingValues.clickGranularity?.map(
								(granularity) => (
									<>
										<If
											condition={granularity == "NoSplit"}
											key={granularity}
										>
											<li>NoSplit</li>
										</If>
										<If
											condition={granularity != "NoSplit"}
											key={granularity}
										>
											<li>
												{granularity} with number of
												clicks:
												{" " +
													Number(
														(
															props.pricingValues as any
														)[
															`clickCount${granularity}` as PricingRequestDataKeys
														]
													)}
											</li>
										</If>
									</>
								)
							)}
						</Typography>
					</Typography>
				)}
				<PrimaryButton
					text="Edit"
					type="button"
					color="secondary"
					sx={{
						...style.overViewFloatingButton,
						width: 160,
					}}
					onClick={() => props.setStep(PricingStepperStep.POWER)}
				>
					<ModeEdit sx={{ color: "#334551" }} />
				</PrimaryButton>
			</Box>
		</Grid>
	);
}
