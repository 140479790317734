import React, { useCallback, useEffect, useMemo } from "react";
import {
	Pricing,
	PricingRunSummaryMetric,
} from "../../pricing_list/pricingListSlice";
import { Column } from "../../../common/components/ColumnsSelector/ColumnsSelector";
import { ColumnIds } from "../../pricing_list/hooks/columns/common";
import { useAppDispatch } from "../../../common/hooks/default";
import { useSelector } from "react-redux";
import { Checkbox, IconButton, LinearProgress, TableCell } from "@mui/material";
import { CollapseArrow } from "../../../common/components/CollapseArrow";
import { If } from "../../../common/components/If";
import { StatelessFlashUpdateTableCell } from "../../../common/components/StatelessFlashUpdateTableCell";
import usePrevious from "../../../common/hooks/usePrevious";
import { formatPercentage } from "../../../common/utils/formatPercentage";
import { primaryColor } from "../../../core/theme";
import { PricingHamburger } from "../../pricing_list/components/PricingTable/PricingHamburger";
import { usePricingTableRowModel } from "../../pricing_list/hooks/columns/usePricingTableRowModel";
import { selectExportLoader } from "../../pricing_list/pricingList.selector";
import CommentIcon from "@mui/icons-material/Comment";
import CommentsDisabledIcon from "@mui/icons-material/CommentsDisabled";
import {
	closePricing,
	expandPricing,
	selectPricing,
} from "../../grouping/grouping.slice";
import { loadPricingRuns } from "../../grouping/grouping.thunk";

export interface PricingLineProps {
	pricing: Pricing;
	isSelectionDisabled: boolean;
	statusIsLoading?: boolean;
	valueOrPercent: "percent" | "value";
	onDisplayComment: (comment: string) => void;
	onDownloadRunToExcel: (ids: number[]) => void;
	onDownloadPbParams: (id: number) => void;
	onDownloadJson: (id: number) => void;
	onDownloadBookingJson: (id: number) => void;
	shouldSpin?: boolean;
	isScrolling?: boolean;
	openGraphModal: (pricing: Pricing | undefined) => void;
	selectedColumns?: Column<ColumnIds>[];
	openWarningModal: (message: string) => void;
}

const style = {
	cell: {
		height: "34px",
		minHeight: "34px",
		padding: 0,
		border: 0,
	},
	stickyRow: {
		zIndex: 1,
		position: "sticky",
		left: 0,
		background: "white",
		"& > td": { padding: "0px" },
		width: "50px !important",
		border: 0,
		height: "34px",
		minHeight: "34px",
	},
};

function PricingLineComponent(props: PricingLineProps) {
	const dispatch = useAppDispatch();
	const {
		pricing,
		isScrolling,
		isSelectionDisabled,
		statusIsLoading,
		valueOrPercent,
		onDisplayComment,
		onDownloadRunToExcel,
		onDownloadJson,
		onDownloadBookingJson,
		onDownloadPbParams,
		openGraphModal,
		selectedColumns,
		openWarningModal,
	} = props;

	const pricingRun = useMemo(() => pricing?.pricing_runs?.[0], [pricing]);

	const isExportLoading = useSelector(selectExportLoader);
	const statusWasLoading = usePrevious(statusIsLoading);

	const isOpen = useMemo(() => pricing.isExpanded, [pricing]);

	const onOpen = useCallback(() => {
		dispatch(expandPricing({ id: pricing.id }));
		dispatch(loadPricingRuns(pricing.id, 1));
	}, [dispatch, pricing]);

	const onClose = useCallback(() => {
		dispatch(closePricing({ id: pricing.id }));
	}, [dispatch, pricing]);

	const setIsOpen = useCallback(
		(open: boolean) => {
			if (open) {
				onOpen();
			} else {
				onClose();
			}
		},
		[onOpen, onClose]
	);

	const refresh = useCallback(() => {
		dispatch(loadPricingRuns(pricing.id, 1));
	}, [pricing, dispatch]);

	const toggleSelection = useCallback(
		(id: number) => {
			dispatch(selectPricing({ id }));
		},
		[dispatch]
	);

	useEffect(() => {
		if (!statusIsLoading && statusWasLoading && isOpen) {
			refresh();
		}
	}, [statusIsLoading, statusWasLoading]);

	const rowModel = usePricingTableRowModel(selectedColumns);

	const getValueOrPercent = useCallback(
		(
			metric: PricingRunSummaryMetric | undefined,
			valueFormatter: (value: number | null | undefined) => string
		) => {
			if (!metric) {
				return "-";
			}
			if (
				valueOrPercent === "percent" &&
				typeof metric.percent === "number"
			) {
				if (!metric.percent) {
					return "-";
				}

				return formatPercentage(metric.percent);
			}
			return valueFormatter(metric.value);
		},
		[valueOrPercent]
	);
	return (
		<>
			<TableCell sx={{ ...style.stickyRow, ...style.cell }}>
				<TableCell
					align="left"
					sx={{
						width: 40,
						minWidth: 40,
						maxWidth: 40,
						...style.cell,
					}}
				>
					<If condition={pricing.actions.length}>
						<Checkbox
							sx={{ height: "24px" }}
							checked={pricing?.isSelected || isSelectionDisabled}
							onChange={() => toggleSelection(pricing.id)}
							disabled={isSelectionDisabled}
						/>
					</If>
				</TableCell>
				<TableCell
					align="left"
					sx={{
						width: 35,
						...style.cell,
					}}
				>
					<CollapseArrow isOpen={isOpen} setIsOpen={setIsOpen} />
				</TableCell>
				{rowModel.stickyColumns.map((column) => (
					<StatelessFlashUpdateTableCell
						key={column.columnId}
						id={column.cellIdFactory(pricing, pricingRun)}
						field={column.fieldValueExtractor({
							openGraphModal,
							pricing,
							valueOrPercent,
							pricingRun,
							statusIsLoading,
							isScrolling,
							getValueOrPercent,
							openWarningModal,
						})}
						isUpdate={pricing?.isNewUpdate}
						align="left"
						sx={{
							maxWidth: column.width,
							minWidth: column.width,
							...style.cell,
						}}
					>
						<column.renderCell
							openGraphModal={openGraphModal}
							pricing={pricing}
							valueOrPercent={valueOrPercent}
							pricingRun={pricingRun}
							statusIsLoading={statusIsLoading}
							isScrolling={isScrolling}
							getValueOrPercent={getValueOrPercent}
							openWarningModal={openWarningModal}
						/>
					</StatelessFlashUpdateTableCell>
				))}
				<If condition={props.shouldSpin}>
					<LinearProgress />
				</If>
			</TableCell>
			<If condition={!isScrolling}>
				{rowModel.nonStickyColumns.map((column) => (
					<StatelessFlashUpdateTableCell
						key={column.columnId}
						id={column.cellIdFactory(pricing, pricingRun)}
						field={column.fieldValueExtractor({
							openGraphModal,
							pricing,
							valueOrPercent,
							pricingRun,
							statusIsLoading,
							isScrolling,
							getValueOrPercent,
							openWarningModal,
						})}
						isUpdate={pricing?.isNewUpdate}
						align="left"
						sx={{
							maxWidth: column.width,
							minWidth: column.width,
							...style.cell,
						}}
					>
						<column.renderCell
							openGraphModal={openGraphModal}
							pricing={pricing}
							valueOrPercent={valueOrPercent}
							pricingRun={pricingRun}
							statusIsLoading={statusIsLoading}
							isScrolling={isScrolling}
							getValueOrPercent={getValueOrPercent}
							openWarningModal={openWarningModal}
						/>
					</StatelessFlashUpdateTableCell>
				))}
				<TableCell
					sx={{ width: 70, maxWidth: 70, ...style.cell }}
					align="center"
				>
					{(pricing?.pricing_runs[0]?.status_comment ||
						pricing?.booking_outputs) && (
						<IconButton
							aria-label="comment"
							onClick={() =>
								onDisplayComment(
									pricing?.booking_outputs ||
										pricing?.pricing_runs[0]
											?.status_comment ||
										""
								)
							}
						>
							<CommentIcon
								sx={{
									fontSize: 16,
									color: primaryColor,
								}}
							/>
						</IconButton>
					)}
					{!pricing?.pricing_runs[0]?.status_comment &&
						!pricing?.booking_outputs && (
							<CommentsDisabledIcon
								sx={{
									fontSize: 16,
									color: primaryColor,
								}}
							/>
						)}
				</TableCell>

				<TableCell
					sx={{ width: 40, maxWidth: 40, ...style.cell }}
					align="left"
				>
					<PricingHamburger
						pricing={pricing}
						isExportLoading={isExportLoading}
						onExportRunToExcel={() =>
							onDownloadRunToExcel([pricing?.pricing_runs[0]?.id])
						}
						onExportRunToJson={() =>
							onDownloadJson(pricing?.pricing_runs[0]?.id)
						}
						onExportRunPbParams={() =>
							onDownloadPbParams(pricing?.pricing_runs[0]?.id)
						}
						onExportBookingToJson={() =>
							onDownloadBookingJson(pricing?.id)
						}
					/>
				</TableCell>
			</If>
		</>
	);
}

export const PricingLine = React.memo(PricingLineComponent);
