import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import {
	getPortfoliosFromPricingRequestForm,
	getSitesFromPricingRequestForm,
} from "../../utils/helpers.utils";
import { PrimaryButton } from "../../../../common/components/CustomButton";
import SiteSelection from "../../../../common/components/SiteSelection";
import { ModeEdit } from "@mui/icons-material";
import { PricingStepperStep } from "../../hooks/usePricingStepper";
import { OverviewCardprops, style } from "./common";

export interface SiteOverviewCardProps extends OverviewCardprops {}

export function SiteOverviewCard(props: SiteOverviewCardProps) {
	const { sitesValues, setStep } = props;
	const [openSiteSelection, setOpenSiteSelection] = useState<any>(false);
	return (
		<Grid item xs={6} sx={style.overviewGridItem}>
			<Box sx={style.overviewBox}>
				<Typography
					variant="h3"
					component="h2"
					sx={style.overviewBoxTitle}
				>
					Sites
				</Typography>
				<Typography component="p">
					{getPortfoliosFromPricingRequestForm(sitesValues).length}{" "}
					portfolio(s)
				</Typography>
				<Typography component="p">
					{getSitesFromPricingRequestForm(sitesValues).length} site(s)
					targeted
				</Typography>
				<PrimaryButton
					onClick={() => setOpenSiteSelection(true)}
					disabled={sitesValues?.length === 0}
					text="See all"
					type="button"
					color="secondary"
					sx={{ marginTop: "15px" }}
				/>
				{openSiteSelection && (
					<SiteSelection
						localModel={sitesValues}
						title="Sites selected"
						enableSearch={false}
						open={openSiteSelection}
						enableSiteSelection={false}
						onClose={() => setOpenSiteSelection(false)}
						isLoading={false}
						onUpdatePage={() => {}}
						currentPage={0}
						onSearch={() => {}}
					/>
				)}

				<PrimaryButton
					text="Edit"
					type="button"
					color="secondary"
					sx={{
						...style.overViewFloatingButton,
						width: 160,
					}}
					onClick={() => setStep(PricingStepperStep.SITE_SELECTION)}
				>
					<ModeEdit sx={{ color: "#334551" }} />
				</PrimaryButton>
			</Box>
		</Grid>
	);
}
