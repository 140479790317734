import { SxProps, Theme } from "@mui/material";
import { Portfolio, Site } from "../../../sites/sites.module";
import { PricingStepperStep } from "../../hooks/usePricingStepper";
import { PricingRequestData } from "../PricingStep";
import { borderNeutralSecondary, white } from "../../../../core/theme";
import React from "react";

export interface OverviewCardprops {
	pricingValues: PricingRequestData;
	sitesValues: (Site | Portfolio)[];
	setStep: (step: PricingStepperStep) => void;
}

export type OverviewCardComponent = (
	props: OverviewCardprops
) => React.ReactElement;

export const style: { [key: string]: SxProps<Theme> } = {
	overviewGridItem: {
		paddingRight: "24px",
		marginBottom: "3em",
		"&:last-of-type": {
			paddingRight: "0px",
		},
	},
	overviewBox: {
		backgroundColor: white,
		border: `1px solid ${borderNeutralSecondary}`,
		textAlign: "left",
		padding: "16px 16px 5em",
		height: "100%",
		position: "relative",
	},
	overviewBoxTitle: {
		fontWeight: "bold",
		marginBottom: "10px",
	},
	overViewFloatingButton: {
		position: "absolute",
		bottom: "0px",
		left: "0px",
		margin: "16px",
	},
};

export const convertToMonthYearFormat = (date: any) => {
	return new Date(date).getMonth() + 1 + "/" + new Date(date).getFullYear();
};
