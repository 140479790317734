import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPathWithParameters, PATH } from "../../../router-path";
import { Portfolio, Site } from "../../sites/sites.module";
import { PricingRequestData } from "../components/PricingStep";
import { transformPricingRequestDataToAdaptApi } from "../utils/helpers.utils";
import { useAppDispatch } from "../../../common/hooks/default";
import { createPricingRequest } from "../PricingRequestStepper.thunk";
import { resetPricingRequestResult } from "../PricingRequestStepper.slice";
import { PRICING_STEPPER_INIT_VALUES } from "../PricingRequestStepper";
import { OverviewCardComponent } from "../components/overview_cards/common";
import { SiteOverviewCard } from "../components/overview_cards/SiteOverviewCard";
import { PowerOverviewCard } from "../components/overview_cards/PowerOverviewCard";
import { GooOverviewCard } from "../components/overview_cards/GooOverviewCard";

export enum PricingStepperType {
	POWER_AND_GOO = "POWER_AND_GOO",
	GOO_ONLY = "GOO_ONLY",
}

export enum PricingStepperStep {
	SITE_SELECTION = "SITE_SELECTION",
	POWER = "POWER",
	GOO_ONLY = "GOO_ONLY",
	OVERVIEW = "OVERVIEW",
}

export const PRICING_STEPPER_CONFIG: Record<
	PricingStepperType,
	PricingStepperStep[]
> = {
	[PricingStepperType.POWER_AND_GOO]: [
		PricingStepperStep.SITE_SELECTION,
		PricingStepperStep.POWER,
		PricingStepperStep.OVERVIEW,
	],
	[PricingStepperType.GOO_ONLY]: [
		PricingStepperStep.SITE_SELECTION,
		PricingStepperStep.GOO_ONLY,
		PricingStepperStep.OVERVIEW,
	],
};

export const PRICING_STEPPER_STEP_LABELS: Record<PricingStepperStep, string> = {
	[PricingStepperStep.SITE_SELECTION]: "Site Selection",
	[PricingStepperStep.POWER]: "Power",
	[PricingStepperStep.GOO_ONLY]: "GOO",
	[PricingStepperStep.OVERVIEW]: "Overview",
};

export const PRICING_STEPPER_OVERVIEW_CARDS: Record<
	PricingStepperType,
	OverviewCardComponent[]
> = {
	[PricingStepperType.POWER_AND_GOO]: [SiteOverviewCard, PowerOverviewCard],
	[PricingStepperType.GOO_ONLY]: [SiteOverviewCard, GooOverviewCard],
};

export function getNextStepperStep(
	type: PricingStepperType,
	currentStep: PricingStepperStep
) {
	const steps = PRICING_STEPPER_CONFIG[type];
	const index = steps.lastIndexOf(currentStep);
	if (index >= 0 && index < steps.length - 1) {
		return steps[index + 1];
	}
	if (index === steps.length - 1) {
		return null;
	}
	throw Error(`Unexpected next step for ${currentStep} for type ${type}`);
}

export function getPreviousStepperStep(
	type: PricingStepperType,
	currentStep: PricingStepperStep
) {
	const steps = PRICING_STEPPER_CONFIG[type];
	const index = steps.lastIndexOf(currentStep);
	if (index >= 1 && index < steps.length) {
		return steps[index - 1];
	}
	if (index === 0) {
		return null;
	}
	throw Error(`Unexpected previous step for ${currentStep} for type ${type}`);
}

export function getStepsLabelsAndNames(
	type: PricingStepperType,
	currentStep: PricingStepperStep
) {
	const steps = PRICING_STEPPER_CONFIG[type];
	const currentStepIndex = steps.indexOf(currentStep);
	const allStepLabels: string[] = steps.map(
		(step) => PRICING_STEPPER_STEP_LABELS[step]
	);

	return {
		currentStepIndex,
		allStepLabels,
	};
}

export function navigateToPricingStepper() {
	const navigate = useNavigate();
	return useCallback(
		(tenderId: number, type: PricingStepperType) => {
			navigate(
				getPathWithParameters(PATH.PRICING_REQUEST, { tenderId, type })
			);
		},
		[navigate]
	);
}

const EMPTY_SITES: any[] = [];

export function usePricingStepper() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { tenderId, type } = useParams();
	const [step, setStep] = useState<PricingStepperStep>(
		PricingStepperStep.SITE_SELECTION
	);
	const [sites, setSites] = useState<(Site | Portfolio)[]>(EMPTY_SITES);
	const [loading, setLoading] = useState<boolean>(false);
	const [pricingValues, setPricingValues] = useState<PricingRequestData>(
		PRICING_STEPPER_INIT_VALUES as PricingRequestData
	);
	const [pricingSuccessModalOpen, setPricingSuccessModalOpen] =
		useState<boolean>(false);

	const nextStep = useMemo(
		() => getNextStepperStep(type as PricingStepperType, step),
		[type, step]
	);
	const goToNextStep = useCallback(() => {
		if (nextStep) {
			setStep(nextStep);
		}
	}, [step, setStep, type, nextStep]);

	const previousStep = useMemo(
		() => getPreviousStepperStep(type as PricingStepperType, step),
		[type, step]
	);
	const canGoBack = useMemo(() => !!previousStep, [previousStep]);
	const goToPreviousStep = useCallback(() => {
		if (previousStep) {
			setStep(previousStep);
		}
	}, [previousStep, setStep]);

	const goToStep = useCallback(
		(newStep: PricingStepperStep) => {
			const steps = PRICING_STEPPER_CONFIG[type as PricingStepperType];
			if (!steps.includes(newStep)) {
				throw new Error(
					`Unexpected transition to step ${newStep} in type ${type}`
				);
			} else {
				setStep(newStep);
			}
		},
		[type]
	);

	const { currentStepIndex, allStepLabels } = getStepsLabelsAndNames(
		type as PricingStepperType,
		step
	);

	const overviewComponents = useMemo(() => {
		return PRICING_STEPPER_OVERVIEW_CARDS[type as PricingStepperType];
	}, [type]);

	const totalSteps = useMemo(() => allStepLabels.length, [allStepLabels]);

	const onSubmit = useCallback(
		async (values: any, { setSubmitting, resetForm }: any) => {
			setSubmitting(false);
			if (nextStep) {
				goToNextStep();
				setPricingValues(values);
			} else {
				setSubmitting(true);
				setLoading(true);
				const data = transformPricingRequestDataToAdaptApi(
					{
						pricingValues: pricingValues,
						sitesValues: sites,
					},
					Number(tenderId)
				);
				const success = await dispatch(createPricingRequest(data));

				setLoading(false);
				if (success) {
					setStep(
						PRICING_STEPPER_CONFIG[type as PricingStepperType][0]
					);
					setPricingValues(
						PRICING_STEPPER_INIT_VALUES as PricingRequestData
					);
					resetForm();
					setPricingSuccessModalOpen(true);
				}
			}
		},
		[nextStep, goToNextStep, setPricingValues]
	);

	const pricingSuccessAction = useCallback(
		(action: string) => {
			switch (action) {
				case "pricing-same-sites":
					setStep(
						PRICING_STEPPER_CONFIG[type as PricingStepperType][1]
					);
					break;
				case "new-pricing":
					dispatch(resetPricingRequestResult());
					setSites([]);
					setStep(
						PRICING_STEPPER_CONFIG[type as PricingStepperType][0]
					);
					break;
				case "pricing-details":
					if (window.location.href.includes("maximized")) {
						navigate(
							getPathWithParameters(
								PATH.MAXIMIZED_TENDER_PRICING_RUN,
								{ tenderId }
							)
						);
					} else {
						navigate(
							getPathWithParameters(PATH.TENDER_PRICING_RUN, {
								tenderId,
							})
						);
					}
					break;
				default:
					if (window.location.href.includes("maximized")) {
						navigate(
							getPathWithParameters(
								PATH.MAXIMIZED_TENDER_PRICING_RUN,
								{ tenderId }
							)
						);
					} else {
						navigate(
							getPathWithParameters(PATH.TENDER_PRICING_RUN, {
								tenderId,
							})
						);
					}
					break;
			}
			setPricingSuccessModalOpen(false);
		},
		[dispatch, setStep, setSites, navigate, type, tenderId]
	);

	return useMemo(
		() => ({
			goToNextStep,
			goToPreviousStep,
			onSubmit,
			type,
			step,
			currentStepIndex,
			allStepLabels,
			totalSteps,
			canGoBack,
			loading,
			pricingSuccessModalOpen,
			setPricingSuccessModalOpen,
			pricingSuccessAction,
			pricingValues,
			setPricingValues,
			sites,
			nextStep,
			setSites,
			setLoading,
			tenderId,
			goToStep,
			overviewComponents,
		}),
		[
			goToNextStep,
			goToPreviousStep,
			onSubmit,
			type,
			step,
			currentStepIndex,
			allStepLabels,
			totalSteps,
			canGoBack,
			loading,
			pricingSuccessModalOpen,
			setPricingSuccessModalOpen,
			pricingSuccessAction,
			pricingValues,
			setPricingValues,
			sites,
			nextStep,
			setSites,
			setLoading,
			tenderId,
			goToStep,
			overviewComponents,
		]
	);
}
