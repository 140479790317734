import { Box } from "@mui/material";
import MenuCard from "./components/MenuCard";
import { ReactComponent as ListingIcon } from "../../common/assets/icons/listing.svg";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import PageContent from "../../common/components/PageContent";
import { PATH } from "../../router-path";

const style = {
	container: {
		display: "flex",
		flexWrap: "wrap",
		alignItems: "start",
		margin: "0 -12px",
	},
};

function LandingPage() {
	return (
		<>
			<PageTitle label="Welcome" />
			<PageContent>
				<Box sx={style.container}>
					<MenuCard
						title="My Tenders"
						description="Create and manage your tenders for your country or your own portfolio"
						icon={ListingIcon}
						link={PATH.TENDERS}
					/>
					<MenuCard
						title="My Contracts"
						description="Keep an eye to the new contracts coming in, those that are booked or to be booked"
						icon={ListingIcon}
						link={PATH.CONTRACTS}
					/>
					<MenuCard
						title="My Sites"
						description="Physical representation of the portfolio, from the park to the asset"
						icon={ListingIcon}
						link={PATH.SITES}
					/>
					<MenuCard
						title="GEODE Reporting"
						description="Cross-check application, reconciliation between GECO & OT, and between GECO & eSales"
						icon={ListingIcon}
						link={PATH.REPORTS}
					/>
					<MenuCard
						title="Data Quality Reporting"
						description="Time series data quality report"
						icon={ListingIcon}
						link={PATH.DATA_QUALITY_REPORTS}
					/>
					<MenuCard
						title="Contract Quality Reporting"
						description="Show errors in contract validation"
						icon={ListingIcon}
						link={PATH.CONTRACT_QUALITY_REPORT}
					/>
					<MenuCard
						title="Site Position Reporting"
						description="Show position of aggregated Representation contracts per site"
						icon={ListingIcon}
						link={PATH.SITE_POSITION_REPORT}
					/>
				</Box>
			</PageContent>
		</>
	);
}

export default LandingPage;
