import React from "react";
import { FormikProps } from "formik";
import { PricingStepperStep } from "./hooks/usePricingStepper";
import { Portfolio, Site } from "../sites/sites.module";
import SiteSelectionStep from "./components/SiteSelectionStep";
import PricingStep from "./components/PricingStep";
import PricingGooStep from "./components/PricingGooStep";
import OverviewStep from "./components/OverviewStep";
import { OverviewCardComponent } from "./components/overview_cards/common";

export interface StepComponentProps {
	formProps: FormikProps<any>;
	step: PricingStepperStep;
	setLoading: (value: boolean) => void;
	tenderId?: string;
	sites: Array<Site | Portfolio>;
	setSites: (value: Array<Site | Portfolio>) => void;
	pricingValues: any;
	setStep: (step: PricingStepperStep) => void;
	overviewComponents: OverviewCardComponent[];
}

export function StepComponent(props: StepComponentProps) {
	switch (props.step) {
		case PricingStepperStep.SITE_SELECTION:
			return (
				<SiteSelectionStep
					sites={props.sites}
					onSelectionChanged={(selectedSites) =>
						props.setSites(selectedSites)
					}
					tenderId={parseInt(props.tenderId || "")}
				/>
			);
		case PricingStepperStep.POWER:
			return (
				<PricingStep
					tenderId={
						props.tenderId ? parseInt(props.tenderId) : undefined
					}
					values={props.formProps.values}
					handleChange={props.formProps.handleChange}
					setFieldValue={props.formProps.setFieldValue}
					errors={props.formProps.errors}
					touched={props.formProps.touched}
				/>
			);
		case PricingStepperStep.GOO_ONLY:
			return (
				<PricingGooStep
					setLoading={props.setLoading}
					values={props.formProps.values}
					sitesValues={props.sites}
					handleChange={props.formProps.handleChange}
					setFieldError={props.formProps.setFieldError}
					setFieldValue={props.formProps.setFieldValue}
					errors={props.formProps.errors}
					touched={props.formProps.touched}
				/>
			);
		case PricingStepperStep.OVERVIEW:
			return (
				<OverviewStep
					pricingValues={props.pricingValues}
					sitesValues={props.sites}
					setStep={props.setStep}
					components={props.overviewComponents}
				/>
			);
	}
}
