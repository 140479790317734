import React from "react";
import { Grid } from "@mui/material";
import { PricingRequestData } from "./PricingStep";
import { Site, Portfolio } from "../../sites/sites.module";
import { PricingStepperStep } from "../hooks/usePricingStepper";
import { OverviewCardComponent } from "./overview_cards/common";

export interface OverviewPricingStepProps {
	pricingValues: PricingRequestData;
	sitesValues: (Site | Portfolio)[];
	setStep: (step: PricingStepperStep) => void;
	components: OverviewCardComponent[];
}

export interface PricingRequest {
	pricingValues: PricingRequestData;
	sitesValues: (Site | Portfolio)[];
}

export default function OverviewStep(props: OverviewPricingStepProps) {
	const { pricingValues, sitesValues, setStep } = props;

	return (
		<Grid container justifyContent="start" alignItems="stretch">
			{props.components.map((Component) =>
				React.createElement(Component, {
					pricingValues,
					sitesValues,
					setStep,
				})
			)}
		</Grid>
	);
}
