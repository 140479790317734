import {
	Alert,
	Box,
	Grid,
	Stack,
	Step,
	StepLabel,
	Stepper,
	SxProps,
	Theme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { lightGrey, white } from "../../core/theme";
import { PrimaryButton } from "../../common/components/CustomButton";
import React, { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import {
	firstOfNextYear,
	pricingGooOnlyStepSchema,
	pricingRequestStepSchema,
} from "./pricingRequestStepper.schema";
import AddCircleIconOutline from "@mui/icons-material/AddCircleOutline";
import { useAppDispatch } from "../../common/hooks/default";
import {
	getCommodityFixings,
	getAllPricingTemplates,
	setNewPricingTemplate,
} from "./PricingRequestStepper.thunk";
import { useSelector } from "react-redux";
import { selectIsPricingTemplateAdded } from "./PricingRequestStepper.selector";

import { getSiteOrPortfolioCountry } from "../sites/sites.module";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import PageContent from "../../common/components/PageContent";
import PricingTemplateModal from "./components/PricingTemplateModal";
import { If } from "../../common/components/If";
import { MAXIMUM_SITES_IN_ONE_GO } from "./PricingRequestStepper.constant";
import PricingSuccessActionModal from "./components/PricingSuccessActionModal";
import { lastDayOfYear, subMonths } from "date-fns";
import {
	PricingStepperStep,
	PricingStepperType,
	usePricingStepper,
} from "./hooks/usePricingStepper";
import { StepComponent } from "./StepComponent";

const style: { [key: string]: SxProps<Theme> } = {
	container: {
		minWidth: "1280px",
		bgcolor: lightGrey,
		"& form": {
			width: "100%",
		},
	},
	stepContainer: {
		paddingTop: "40px",
	},
	titleContainer: {
		width: "100%",
		alignItems: "center",
		marginBottom: "45px",
	},
	title: {
		marginBottom: "30px",
		flexGrow: "2",
		margin: 0,
		fontSize: 36,
	},
	stepperHeader: {
		marginTop: "40px",
		backgroundColor: white,
		minHeight: "72px",
		paddingLeft: "100px",
		paddingRight: "100px",
		border: "1px solid #000000",
	},
};

const getStepSchema = (step: PricingStepperStep) => {
	switch (step) {
		case PricingStepperStep.POWER:
			return pricingRequestStepSchema;
		case PricingStepperStep.GOO_ONLY:
			return pricingGooOnlyStepSchema;
		default:
			return undefined;
	}
};

export const PRICING_STEPPER_INIT_VALUES = {
	pricingType: [],
	gooPricingType: [],
	startDate: firstOfNextYear,
	endDate: lastDayOfYear(firstOfNextYear),
	gooStartDate: firstOfNextYear,
	gooEndDate: lastDayOfYear(firstOfNextYear),
	pricingDate: undefined,
	live: false,
	baseOptionSwitch: false,
	baseCapacity: "",
	clickOptionSwitch: false,
	clickGranularity: ["NoSplit"],
	clickCountYear: 1,
	clickCountQuarter: 1,
	clickCountMonth: 1,
	clickCountNoSplit: 0,
	clickCount: 1,
	granularityMonthly: false,
	granularityQuarter: false,
	alpha: false,
	beta: false,
	granularityCal: false,
	granularityWholeHorizon: true,
	includingGoos: false,
	clickabilityPlanningSwitch: false,
	clickabilityPlanning: "",
	tasOrFormula: "",
	formula: "",
	priceCap: "",
	priceFloor: "",
	priceFloorSwitch: false,
	priceCapSwitch: false,
	template: "",
	chapter51Switch: false,
	chapter51Type: "",
	chapter51NumberOfHours: 0,
	fixPriceSwitch: false,
	ppaWithGooSwitch: false,
	formulaSwitch: false,
	gooPowerAdjustment: 0,
	clickLimitDate: subMonths(firstOfNextYear, 3),
	negativePriceValue: 0,
	hasNegativePrice: false,
};

function PricingRequestStepperComponent() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { backPage } = useParams();
	const {
		goToPreviousStep,
		onSubmit,
		step,
		currentStepIndex,
		allStepLabels,
		loading,
		pricingSuccessModalOpen,
		pricingSuccessAction,
		pricingValues,
		sites,
		nextStep,
		setSites,
		setLoading,
		tenderId,
		goToStep,
		overviewComponents,
		type: pricingType,
	} = usePricingStepper();

	const [isPricingTemplateModalOpen, setPricingTemplateModalOpen] =
		useState<boolean>(false);

	const isPricingTemplateAdded = useSelector(selectIsPricingTemplateAdded);

	const country = useMemo(
		() => getSiteOrPortfolioCountry(sites?.[0]),
		[sites]
	);

	useEffect(() => {
		const timeout = setTimeout(
			() =>
				dispatch(
					getCommodityFixings(
						(pricingType as PricingStepperType) ||
							PricingStepperType.POWER_AND_GOO
					)
				) && dispatch(getAllPricingTemplates()),
			400
		);
		return () => clearTimeout(timeout);
	}, [country, pricingType]);

	const navigateBack = () => {
		if (backPage) {
			navigate(`/${backPage}`);
		} else {
			navigate(-1);
		}
	};
	const handlePricingTemplateSave = async (title: string) => {
		await dispatch(setNewPricingTemplate(title, pricingValues));
		if (isPricingTemplateAdded) setPricingTemplateModalOpen(false);
	};

	return (
		<>
			<PageTitle
				label="New pricing request"
				leftSide={
					<PrimaryButton
						text="Exit"
						type="button"
						color="info"
						sx={{
							width: 106,
							color: "#171D21",
						}}
						onClick={navigateBack}
					>
						<ChevronLeftIcon />
					</PrimaryButton>
				}
			/>
			<PageContent>
				<Box display={"flex"} justifyContent="center">
					<Stack
						sx={style.container}
						direction="column"
						justifyContent="space-between"
						alignItems="center"
						maxWidth={"1000px"}
						spacing={2}
					>
						<Formik
							enableReinitialize={true}
							validateOnChange={false}
							initialValues={PRICING_STEPPER_INIT_VALUES}
							validationSchema={getStepSchema(step)}
							onSubmit={onSubmit}
						>
							{(formProps) => {
								return (
									<form onSubmit={formProps.handleSubmit}>
										<Grid
											sx={style.titleContainer}
											container
											direction="row"
											justifyContent="space-between"
											alignItems="center"
										>
											<Grid item xs={12}>
												<Stepper
													activeStep={
														currentStepIndex
													}
													sx={style.stepperHeader}
												>
													{allStepLabels.map(
														(label, index) => (
															<Step key={index}>
																<StepLabel>
																	{label}
																</StepLabel>
															</Step>
														)
													)}
												</Stepper>
												<Grid
													sx={style.stepContainer}
													container
													direction="row"
													justifyContent="flex-start"
													alignItems="center"
												>
													<StepComponent
														formProps={formProps}
														step={step}
														setLoading={setLoading}
														sites={sites}
														setSites={setSites}
														tenderId={tenderId}
														pricingValues={
															pricingValues
														}
														setStep={goToStep}
														overviewComponents={
															overviewComponents
														}
													/>
												</Grid>
											</Grid>
										</Grid>
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems="center"
											spacing={2}
											width="100%"
										>
											<PrimaryButton
												text="Cancel"
												type="button"
												color="secondary"
												onClick={navigateBack}
											/>
											{!nextStep && (
												<PrimaryButton
													type="button"
													text="Save as template"
													color="primary"
													disabled={loading}
													onClick={() =>
														setPricingTemplateModalOpen(
															true
														)
													}
												>
													<AddCircleIconOutline
														sx={{
															color: "#fff",
														}}
													/>
												</PrimaryButton>
											)}
											<If
												condition={
													step ===
														PricingStepperStep.SITE_SELECTION &&
													sites.length >
														MAXIMUM_SITES_IN_ONE_GO
												}
											>
												<Alert severity="error">
													Too many sites selected in a
													single request (max{" "}
													{MAXIMUM_SITES_IN_ONE_GO}
													), please do multiple
													requests
												</Alert>
											</If>
											<Stack
												direction="row"
												justifyContent="space-between"
												alignItems="center"
												spacing={2}
											>
												<PrimaryButton
													onClick={goToPreviousStep}
													isDisabled={
														step ===
															PricingStepperStep.SITE_SELECTION ||
														loading
													}
													text="Back"
													type="button"
													color="secondary"
												/>
												{!!nextStep && (
													<PrimaryButton
														type="submit"
														text="Next"
														isDisabled={
															sites.length >
																MAXIMUM_SITES_IN_ONE_GO ||
															!sites.length ||
															loading
														}
														color="primary"
														onClick={() => {
															formProps.setFieldValue(
																"$isSubmitting",
																true
															);
														}}
													/>
												)}
												{!nextStep && (
													<PrimaryButton
														type="submit"
														text="Add Pricing"
														color="primary"
														disabled={loading}
														onClick={() => {
															formProps.setFieldValue(
																"$isSubmitting",
																true
															);
														}}
													>
														<AddCircleIconOutline
															sx={{
																color: "#fff",
															}}
														/>
													</PrimaryButton>
												)}
											</Stack>
										</Stack>
									</form>
								);
							}}
						</Formik>
					</Stack>
				</Box>
				<PricingTemplateModal
					open={isPricingTemplateModalOpen}
					onClose={() => setPricingTemplateModalOpen(false)}
					onSubmit={(title) => handlePricingTemplateSave(title)}
				/>
				<PricingSuccessActionModal
					open={pricingSuccessModalOpen}
					onSubmit={(action) => {
						pricingSuccessAction(action);
					}}
				/>
			</PageContent>
		</>
	);
}

const PricingRequestStepper = React.memo(PricingRequestStepperComponent);
export default PricingRequestStepper;
