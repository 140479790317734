import { Box, SxProps, Theme } from "@mui/material";
import {
	GenericTableRowTooltip,
	GenericTableRowTooltipProps,
} from "../../../../common/components/GenericTableRowTooltip";

export default function PricingTableRowBox(props: GenericTableRowTooltipProps) {
	const { value, sx } = props;
	const boxSx: SxProps<Theme> = {
		...sx,
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
	};
	return (
		<Box sx={boxSx}>
			<GenericTableRowTooltip value={value ?? ""} />
		</Box>
	);
}
