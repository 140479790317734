import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	IconButton,
	Radio,
	RadioGroup,
	Switch,
	SxProps,
	Theme,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import en from "date-fns/locale/en-GB";
import CheckboxesTags from "../../../common/components/CheckboxesTags";
import CustomDatePicker from "../../../common/components/CustomDatePicker";
import CustomInput from "../../../common/components/CustomInput";
import {
	CLICK_GRANULARITY,
	PRICING_GRANULARITY,
} from "../PricingRequestStepper.constant";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { lightGrey } from "../../../core/theme";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCommodityFixingsState } from "../PricingRequestStepper.selector";
import { CommodityFixings } from "../PricingRequestStepper.slice";
import { Portfolio, Site } from "../../sites/sites.module";
import { endOfYear, subMonths } from "date-fns";
import { PricingRequestData } from "./PricingStep";
import * as _ from "lodash";

const style: { [key: string]: SxProps<Theme> } = {
	radioGroup: {
		flexDirection: "row",
		textTransform: "capitalize",
		marginBottom: "5px",
	},
	titlePricingType: {
		marginBottom: "32px",
		fontWeight: 700,
	},
	titleOptions: {
		fontWeight: 700,
	},
	datePicker: {
		width: "100%",
		"& p": {
			backgroundColor: lightGrey,
		},
	},
	adjustmentPower: {
		width: "200px",
		"& p": {
			backgroundColor: lightGrey,
		},
	},
	granularityCheckBox: {
		flexDirection: "column",
		textTransform: "capitalize",
	},
	clickCount: {
		width: "160px",
		"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
			{
				WebkitAppearance: "none",
				margin: 0,
			},
		"& input": {
			textAlign: "center",
		},
		"& input::placeholder": {
			textAlign: "center",
		},
		"& svg": {
			color: "grey",
		},
	},
	pricingDate: {
		width: "100%",
	},
};

interface PricingGooStepProps {
	values: PricingRequestData;
	handleChange: any;
	setFieldValue: (fieldName: string, value: any) => void;
	setFieldError: (fieldName: string, value: any) => void;
	errors: any;
	touched: any;
	sitesValues: (Site | Portfolio)[];
	setLoading: (value: boolean) => void;
}

function PricingGooStep(props: PricingGooStepProps) {
	const { values, handleChange, setFieldValue, errors, touched } = props;
	const pricingTypes = useSelector(selectCommodityFixingsState);

	const onPricingTypeChanges = useCallback(
		(fieldName: string, fieldValues: CommodityFixings[]) => {
			setFieldValue(fieldName, fieldValues);
			if (_.some(fieldValues.map((value) => !value.has_clickability))) {
				setFieldValue("clickOptionSwitch", false);
			}
		},
		[setFieldValue]
	);

	useEffect(() => {
		setFieldValue("clickGranularity", [CLICK_GRANULARITY[0]]);
	}, []);

	return (
		<Grid container columnSpacing={4} rowSpacing={2}>
			<Grid item xs={12} display="flex" justifyContent="flex-start">
				<Typography
					variant="h6"
					component="h6"
					sx={style.titlePricingType}
				>
					Goo pricing type
				</Typography>
			</Grid>
			<Grid item xs={12}>
				{Array.isArray(values.pricingType) && (
					<CheckboxesTags<CommodityFixings>
						label="Pricing type"
						trackBy="name"
						name="pricingType"
						value={values.pricingType}
						options={pricingTypes}
						setFieldValue={onPricingTypeChanges}
						error={!!errors.pricingType && touched.pricingType}
						helperText={
							!!errors.pricingType && touched.pricingType
								? errors.pricingType ||
								  "Please select at least one pricing type"
								: ""
						}
					/>
				)}
			</Grid>
			<Grid item xs={6}>
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					adapterLocale={en}
				>
					<CustomDatePicker
						format={"MM/yyyy"}
						name="startDate"
						sx={style.datePicker}
						views={["month", "year"]}
						onChange={(value) => {
							if (value) {
								const startDate = new Date(value as string);
								const endDate = endOfYear(startDate);
								setFieldValue("endDate", endDate);
								setFieldValue(
									"clickLimitDate",
									subMonths(startDate, 3)
								);
							}
						}}
						slotProps={{
							textField: {
								size: "small",
								label: "Start date",
								error:
									!!errors.startDate && !!touched.startDate,
								helperText:
									!!errors.startDate && touched.startDate
										? errors.startDate
										: "",
							},
						}}
					/>
				</LocalizationProvider>
			</Grid>
			<Grid item xs={6}>
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					adapterLocale={en}
				>
					<CustomDatePicker
						format={"MM/yyyy"}
						name="endDate"
						minDate={values.startDate}
						sx={style.datePicker}
						views={["month", "year"]}
						lastDayOfTheMonth
						slotProps={{
							textField: {
								size: "small",
								label: "End date (month/year)",
								error: !!errors.endDate && !!touched.endDate,
								helperText:
									!!errors.endDate && touched.endDate
										? errors.endDate
										: "",
							},
						}}
					/>
				</LocalizationProvider>
			</Grid>

			<Grid item xs={12} display="flex" justifyContent="flex-start">
				<FormGroup sx={style.granularityCheckBox}>
					<FormLabel sx={{ display: "flex" }}>Granularity</FormLabel>
					<Box>
						<FormControlLabel
							name="granularityMonthly"
							control={
								<Checkbox
									onChange={handleChange}
									checked={values.granularityMonthly}
								/>
							}
							label={PRICING_GRANULARITY[0]}
						/>
						<FormControlLabel
							name="granularityQuarter"
							control={
								<Checkbox
									onChange={handleChange}
									checked={values.granularityQuarter}
								/>
							}
							label={PRICING_GRANULARITY[1]}
						/>
						<FormControlLabel
							name="granularityCal"
							control={
								<Checkbox
									onChange={handleChange}
									checked={values.granularityCal}
								/>
							}
							label={PRICING_GRANULARITY[2]}
						/>
						<FormControlLabel
							name="granularityWholeHorizon"
							control={
								<Checkbox
									onChange={handleChange}
									checked={values.granularityWholeHorizon}
								/>
							}
							label={PRICING_GRANULARITY[3]}
						/>
					</Box>
				</FormGroup>
			</Grid>
			<Grid item xs={10} display="flex" justifyContent="flex-start">
				<Typography variant="h6" component="h6" sx={style.titleOptions}>
					Parameters
				</Typography>
			</Grid>
			<Grid
				item
				xs={3}
				display="flex"
				flexDirection={"column"}
				justifyContent="flex-start"
			>
				<FormGroup>
					<FormControlLabel
						control={
							<Switch
								name={"clickOptionSwitch"}
								checked={values.clickOptionSwitch}
								onChange={handleChange}
							/>
						}
						label="Clickability Options"
					/>
				</FormGroup>
				<FormControl required>
					<RadioGroup
						value={values.clickGranularity}
						name="clickGranularity"
						onChange={handleChange}
						sx={style.radioGroup}
					>
						<FormControlLabel
							value={CLICK_GRANULARITY[0]}
							control={<Radio />}
							label={CLICK_GRANULARITY[0]}
						/>
					</RadioGroup>
				</FormControl>
				<CustomInput
					id="clickCount"
					name="clickCount"
					type="number"
					value={values.clickCount}
					onChange={handleChange}
					sx={style.clickCount}
					placeholder="1"
					label="Number of clicks"
					errorText={errors.clickCount}
					touched={touched.clickCount}
					InputProps={{
						startAdornment: (
							<IconButton
								onClick={() => {
									setFieldValue(
										"clickCount",
										Number(values.clickCount) > 1
											? Number(values.clickCount) - 1
											: 0
									);
									return (
										document.getElementById(
											"clickCount"
										) as any
									).stepDown();
								}}
							>
								<RemoveIcon />
							</IconButton>
						),
						endAdornment: (
							<IconButton
								onClick={() => {
									setFieldValue(
										"clickCount",
										Number(values.clickCount) + 1
									);
									return (
										document.getElementById(
											"clickCount"
										) as any
									).stepUp();
								}}
							>
								<AddIcon />
							</IconButton>
						),
					}}
				></CustomInput>
			</Grid>
			<Grid
				item
				xs={3}
				display="flex"
				flexDirection={"column"}
				justifyContent="flex-start"
			>
				<Box sx={{ marginTop: "85px" }}>
					<LocalizationProvider
						dateAdapter={AdapterDateFns}
						adapterLocale={en}
					>
						<CustomDatePicker
							name="clickLimitDate"
							sx={style.datePicker}
							views={["month", "year"]}
							lastDayOfTheMonth
							slotProps={{
								textField: {
									size: "small",
									label: "Click limit date",
									error:
										!!errors.clickLimitDate &&
										!!touched.clickLimitDate,
									helperText:
										!!errors.clickLimitDate &&
										touched.clickLimitDate
											? errors.clickLimitDate
											: "",
								},
							}}
						/>
					</LocalizationProvider>
				</Box>
			</Grid>
		</Grid>
	);
}

export default PricingGooStep;
